<template>
  <router-view></router-view>
</template>



<style lang="scss">
@import '@/assets/styles/reset.scss';
@import '@/assets/styles/iconfont.scss';
@import '@/assets/styles/common.scss';
</style>
