import http from '@/utils/http';
import type { MutationTree, ActionTree, GetterTree } from 'vuex'
import type { State } from '../index'

interface Infos {
  [index: string]: unknown
}

export interface MindsState {
  wordInfos: Infos,
  selectedNodeID:string,
  wordFullForm:string
  closeMindMain:boolean
  isMiddleWindow:boolean
}

const state: MindsState = {
  wordInfos: {},
  selectedNodeID:"",
  wordFullForm:"",
  closeMindMain:false,
  isMiddleWindow:false
};

const mutations: MutationTree<MindsState> = {
  updateWordInfos(state, payload){
    state.wordInfos = payload;
  },
  updateSelectedNodeID(state, payload){
    state.selectedNodeID = payload;
  },
  updateWordFullForm(state, payload){
    state.wordFullForm = payload;
  },
  updateCloseMindMain(state, payload){
    state.closeMindMain = payload;
  },

  updateIsMiddleWindow(state, payload){
    state.isMiddleWindow = payload;
  }
};

const actions: ActionTree<MindsState, State> = {
  queryWordExplain(context, payload){
    return http.post('/search/user/searchWordRedis', payload);
  },

  queryMind(context, payload){
    return http.post('/search/user/searchMind', payload);
  },

  queryExplain(context, payload){
    return http.post('/search/user/searchMindsExplain', payload);
  },
};
const getters: GetterTree<MindsState, State> = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}