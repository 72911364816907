import http from '@/utils/http';
import type { MutationTree, ActionTree, GetterTree } from 'vuex'
import type { State } from '../index'

interface Infos {
  [index: string]: unknown
}

export interface PaysState {
  wordInfos: Infos,
  selectedNodeID: string,
  wordFullForm: string
}

const state: PaysState = {
  wordInfos: {},
  selectedNodeID: "",
  wordFullForm: ""
};

const mutations: MutationTree<PaysState> = {
  // updateWordInfos(state, payload){
  //   state.wordInfos = payload;
  // },
};

const actions: ActionTree<PaysState, State> = {
  // //可以同时设置路径参数、请求参数、请求体参数
  // wxpayNativeType(context, { pathParams, queryParams, requestBody }) {
  //   const url = `/wxpay/native/${pathParams}`;
  //   return http.post(url, requestBody, {
  //     params: queryParams
  //   });
  // },

  wxpayNativeType(context, payload) {
    return http.post('/wxpay/native/payProduct',payload);
  },

  wxpayPayAccount(context, payload) {
    return http.post('/wxpay/native/payAccount', payload);
  },

  checkOrderStatus(context, pathParams) {
    const url = `/orderinfo/queryOrderStatus/${pathParams}`;
    return http.post(url);
  },

  orderList(context, payload) {
    return http.post('/orderinfo/list',payload);
  },

};
const getters: GetterTree<PaysState, State> = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}