import { createStore, useStore as baseUseStore } from 'vuex'
//引入useStore目的是要对其进行二次封装，避免每次调用都需要引入key比较麻烦
import type { Store } from 'vuex'

//引入vuex-persist：
import VuexPersistence from 'vuex-persist'


//引入子模块：
import users from './modules/users'
import searches from './modules/searches'
import minds from './modules/minds'
import pays from './modules/pays'

//引入接口：
import type { UsersState } from './modules/users'
import type { SearchesState } from './modules/searches'
import type { MindsState } from './modules/minds'
import type { PaysState } from './modules/pays'
import type { InjectionKey } from 'vue'

export interface State {
}

//配接口并对外暴露
export interface StateAll extends State {
  users: UsersState,
  searches: SearchesState,
  minds: MindsState,
  pays: PaysState
}

//对外提供key：就是将modules子模块结合在一起且让类型推断自动生效：
export const key: InjectionKey<Store<StateAll>> = Symbol()

//封装useStore（引入key）并对外暴露
export function useStore() {
  return baseUseStore(key)
}

//封装searchesStore（引入key）并对外暴露
export function searchesStore() {
  return baseUseStore(key)
}

//封装mindsStore（引入key）并对外暴露
export function mindsStore() {
  return baseUseStore(key)
}

//封装paysStore（引入key）并对外暴露
export function paysStore() {
  return baseUseStore(key)
}

//定义实例化vuex-persist
const vuexLocal = new VuexPersistence<State>({
  storage: window.localStorage,
  //指定把想要的数据进行持久化，这里只对token：我们要在usersState获取token进行持久化，由于users（usersState）之前挂载到了StateAll上，所以state需要用(state as StateAll)断言成StateAll：
  reducer: (state) => (
    { users: 
      { token: (state as StateAll).users.token, 
        infos: (state as StateAll).users.userInfos,
        userId: (state as StateAll).users.userId,
        head: (state as StateAll).users.head, 
        nickname: (state as StateAll).users.nickname,
        mobile: (state as StateAll).users.mobile,
        vipTime: (state as StateAll).users.vipTime,
        rechargeAmount: (state as StateAll).users.rechargeAmount
      },
      searches:{
        mindIERCode: (state as StateAll).searches.mindIERCode,
        searchBarWord:(state as StateAll).searches.searchBarWord
      },
      minds:{
        wordInfos: (state as StateAll).minds.wordInfos,
        selectedNodeID: (state as StateAll).minds.selectedNodeID,
        wordFullForm: (state as StateAll).minds.wordFullForm
      }
     }),
})

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {  //让子模块生效
    users,
    searches,
    minds,
    pays
  },
  //使插件生效：
  plugins: [vuexLocal.plugin]
})