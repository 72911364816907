// src/directives/intersect.ts
import { DirectiveBinding } from 'vue';

interface IntersectionDirectiveBinding extends DirectiveBinding {
  value: (entry: IntersectionObserverEntry) => void;
}

const intersect = {
  beforeMount(el: HTMLElement, binding: IntersectionDirectiveBinding) {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    };

    const callback = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          binding.value(entry);
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);
    observer.observe(el);
  }
};

export default intersect;