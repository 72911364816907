import http from '@/utils/http';
import type { MutationTree, ActionTree, GetterTree } from 'vuex'
import type { State } from '../index'

//Infos返回的类型不确定，写索引签名为unknown
interface Infos {
  [index: string]: unknown
}

export interface UsersState {
  token: string
  userInfos: Infos
  userId: string
  head: string
  nickname: string
  mobile: string
  vipTime: string
  rechargeAmount: string
}

//定义token，初始值为""
const state: UsersState = {
  token: '',
  userInfos: {},
  userId: '',
  head: '',
  nickname: '',
  mobile: '',
  vipTime: '',
  rechargeAmount: ''
};

const mutations: MutationTree<UsersState> = {
  //定义一个在状态管理中存储token的方法：参数类型不需要写，自动推断好了
  updateToken(state, payload) {
    state.token = payload;
  },
  //定义更新（重新存储）用户infos的方法：
  updateInfos(state, payload) {
    state.userInfos = payload;
  },
  updateHead(state, payload) {
    state.head = payload;
  },
  updateUserId(state, payload) {
    state.userId = payload;
  },
  updateNickName(state, payload) {
    state.nickname = payload;
  },
  updateMobile(state, payload) {
    state.mobile = payload;
  },
  updateVipTime(state, payload) {
    state.vipTime = payload;
  },
  updateRechargeAmount(state, payload) {
    state.rechargeAmount = payload;
  },
  clearToken(state) {
    state.token = '';
  },
};

//发起axios请求：
const actions: ActionTree<UsersState, State> = {
  //定义一个post请求：payload，载荷，就是需要传给后端的封装好的账号和密码对象数据，在Login.vue中是ruleForm对象：
  login(context, payload) {
    return http.post('/passport/loginWithPassword', payload)
  },

  //定义验证token是否合法的请求接口：
  infos(context, payload) {
    return http.get('/passport/user/info', payload)
  },

  //发送短信验证码
  smsCode(context, payload) {
    return http.post('/passport/getSMSCode', payload)
  },

  //判断手机号是否已经被注册过：
  isExist(context, payload) {
    return http.post('/passport/isMobileExist', payload)
  },

  //手机号密码注册：
  signUp(context, payload) {
    return http.post('/passport/signUpMobilePasswordToLogin', payload)
  },


  //检查是否微信注册或登录成功
  setLoginState(context, payload) {
    return http.post('/passport/wx/setLoginState', payload)
  },

  //检查是否微信注册或登录成功
  checkWxCallback(context, payload) {
    return http.post('/passport/wx/checkWxCallback', payload)
  },


  //获取微信二维码
  getGzhQrcode(context, payload) {
    return http.post('/passport/wx/getGzhQrcode', payload)
  },

  //检查是否扫码微信公众号登录
  checkGZHLogin(context, payload) {
    return http.post('/passport/wx/checkGZHLogin', payload)
  },

  changePassword(context, payload) {
    return http.post('/passport/changePassword', payload)
  },

  bindingOrChangeMobile(context, payload) {
    return http.post('/passport/bindingOrChangeMobile', payload)
  },

};

const getters: GetterTree<UsersState, State> = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}