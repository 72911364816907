import http from '@/utils/http';
import type { MutationTree, ActionTree, GetterTree } from 'vuex'
import type { State } from '../index'

//Infos返回的类型不确定，写索引签名为unknown
interface Infos {
  [index: string]: unknown
}

export interface SearchesState {
  infos: Infos,
  searchBarWord: string,
  mindIERCode:string
}

//定义token，初始值为""
const state: SearchesState = {
  infos: {},
  searchBarWord: '',
  mindIERCode:''
};

const mutations: MutationTree<SearchesState> = {

  searchBarWord(state, payload) {
    state.searchBarWord = payload;
  },

  mindIERCode(state, payload) {
    state.mindIERCode = payload;
  },
};

//发起axios请求：
const actions: ActionTree<SearchesState, State> = {
  //定义一个post请求：payload，载荷，就是需要传给后端的封装好的账号和密码对象数据，在Login.vue中是ruleForm对象：
  search(context, payload) {
    return http.post('/search/user/wildSearchWords', payload)
  }
};

const getters: GetterTree<SearchesState, State> = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}